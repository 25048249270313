<template>
  <div class="wrap">
    <van-form input-align="right" error-message-align="right">
      <van-field readonly label="我司账户名称" v-if='list.acc_name' :value="list.acc_name" />
      <van-field readonly label="我司账号" v-if='list.acc_num' :value="list.acc_num" />
      <van-field readonly v-if='list.acc_bank' class="date" type="textarea" autosize label="我司账户开户行" rows="2" :value="list.acc_bank" />
      <van-field readonly label="手续费" v-if='list.fee' :value="list.fee + '元'" />
      <van-cell v-if="list.fee_url" title="转账截图" is-link :to="{ path: '/upFileDetail', query: { contractNum: list.con_no,wxid: list.wxid,feeUrl: list.fee_url}}" value="已上传" />
      <van-field readonly label="姓名" :value="list.customername" />
      <van-field autosize readonly label="合同号" :value="list.contract_number" />
      <van-field readonly label="车架号" :value="list.vin" />
      <van-field readonly label="变更后还款日" :value="list.newdate" />
      <van-field class="date" readonly label="变更后计划日期" :value="list.new_plan_day" />
      <van-field readonly label="多还款租息" :value="list.add_interest" />
      <van-field readonly label="申请时间" :value="list.apply_time" />
    </van-form>
    <div v-if="list.approval_status=='2'">
      <div offset="1" style="color:#1A1A1A;font-size:20px;margin:15px;">拒绝原因：</div>
      <div class="tips">
        <p>{{list.back_msg}} </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
  data() {
    return {
      list:this.$store.state.dataDetailList,
    };
  },
  mounted(){
    // let that = this;
    // sessionStorage.setItem('key', 'repayment3');
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    // 	if(sessionStorage.getItem('key') == 'repayment3') {
    //     that.$router.push({ path: '/application?flag=1' });
    //   }
    // }, false);
  },
  created(){
    window.scrollTo()
  },
  methods: {
    onLoad() {
    },
  },
};
</script>
<style scoped>
.wrap{
  padding: 10px 0px; 
  background-color: #fff; 
  height: 100%;
  }
.tips{
  color:#808080;
  font-size:13px;
  background-color: #F9F9F9;
  margin: 10px;
  min-height:50px;
  padding: 15px;
  /* margin-left: 12px; */
}
.upflie .van-cell__value{
  color: #323233;
}
.upflie .van-cell__title{
  color: #646566;
}
</style>
<style>
textarea{
  overflow: hidden;
}
.date .van-field__label{
  width: 9em;
}
.wrap .van-field__control {
    color: #666666 !important;
}
.upflie .van-cell__value{
  color: #666666 !important;
}
.upflie .van-cell__title{
  color: #646566;
}
.van-cell{
  color: #646566;
}
.van-cell__value{
  color: #666666;
}
</style>